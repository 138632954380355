<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { debounce } from "@/helpers/debounce";

export default {
    page: {
        title: "Daftar Banding",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        Datatable,
        Pagination,
    },
    data() {
        let sortOrders = []; // select sortOrder

        // definisi kolom yang dipakai
        let columns = [
            { width: "50px", label: "No", name: "-" },
            { width: "auto", label: "Nomor Rapat Banding", name: "-" },
            { width: "auto", label: "Tanggal Rapat Banding", name: "-" },
            { width: "auto", label: "Asesor Banding", name: "-" },
            { width: "auto", label: "Status", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
            { width: "200px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
        ];

        // cek sorting
        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            title: "Daftar Banding",
            items: [
                {
                    text: "Pasca Asesmen",
                    href: "/",
                },
                {
                    text: "Daftar Banding",
                    active: true,
                },
            ],
            namePathUrl: this.$route.name,
            sessionRoleId: localStorage.session_role_active_id,
            // variable Page Table
            loadingTable: true,
            table_data: [],
            columns: columns,
            sortKey: "", // sort key awal
            sortOrders: sortOrders,
            currentTablePage: "",

            tanggal_dari : null,
            tanggal_sampai : null,

            // parsing data ke table data
            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "asc",
                filter_status: true, // untuk filtering data
                tanggal_dari: null,
                tanggal_sampai: null,
                role_id : localStorage.session_role_active_id,
            },

            // config untuk paginasi
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
                links: "",
            },
        };
    },
    created() {
        this.searchDatatable = debounce(() => {
        this.getDataTable();
        // call fetch API to get results
        }, 500);
    },
    mounted() {
        this.getDataTable();
    },
    methods: {
        fullDateTimeFormat(date) {
            moment.locale('id');
            return moment(date).format("DD MMMM YYYY") 
        },
        toPage: function (url) {
            let self = this;
            self.getDataTable(url);
        },
        ubahStatus(id) {
            console.log(id);
            var alert_text = "Apakah Anda yakin untuk mengubah status?";
            Swal.fire({
                title: "Peringatan !",
                text: alert_text,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ya",
                cancelButtonText: "Tidak",
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.close();
                } else {
                    Swal.close();
                }
            });
        },
        filterDatatable() {
            let self = this;
            self.tableData.tanggal_dari = self.tanggal_dari;
            self.tableData.tanggal_sampai = self.tanggal_sampai;
            // self.tableData.asesor_id = self.asesi_selected?.user_id;
            self.getDataTable();
        },
        getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-banding") {
            let self = this;
            self.loadingTable = true;
            self.tableData.draw++;

            var config = {
                method: "get",
                url: url,
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    let response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        if (self.tableData.draw == response_data_fix.draw) {
                            self.table_data = response_data_fix.list_data.data;
                            self.configPagination(response_data_fix.list_data);
                            // self.configPagination(response_data_fix.list_data);
                            self.currentTablePage = response_data_fix.list_data.current_page;
                            console.log(response_data_fix.list_data.data);
                            self.loadingTable = false;
                        }
                        self.loadingTable = false;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        // eksporPdf() {
        //     let self = this;
        //     const link = document.createElement("a");
        //     link.href = process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-banding/pdf?tanggal_dari="+self.tanggal_dari+"&tanggal_sampai="+self.tanggal_sampai;
        //     link.setAttribute('target', '_blank');
        //     link.click();
        // }, 
        eksporPdf(){
            let self = this;
            self.tableData.tanggal_dari = self.tanggal_dari;
            self.tableData.tanggal_sampai = self.tanggal_sampai;
            let config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-banding/pdf",
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
                responseType: 'blob',
            };
            axios(config)
                .then(function (response) {
                    var blob = new Blob([response.data], { type: 'application/pdf' })
                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'Daftar Banding.pdf'
                    link.click()
                })
                .catch(function (error) {
                    console.log(error);
                });  
        },
        eksporExcel(){
            let self = this;
            self.tableData.tanggal_dari = self.tanggal_dari;
            self.tableData.tanggal_sampai = self.tanggal_sampai;
            let config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-banding/excel",
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
                responseType: 'blob',
            };
            axios(config)
                .then(function (response) {
                    var blob = new Blob([response.data], { type: 'application/vnd-ms-excel' })
                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'Daftar Banding.xls'
                    link.click()
                })
                .catch(function (error) {
                    console.log(error);
                });  
        },
        configPagination(data) {
            console.log(data);
            let self = this;
            self.pagination.lastPage = data.last_page;
            self.pagination.currentPage = data.current_page;
            self.pagination.total = data.total;
            self.pagination.lastPageUrl = data.last_page_url;
            self.pagination.nextPageUrl = data.next_page_url;
            self.pagination.prevPageUrl = data.prev_page_url;
            self.pagination.from = data.from;
            self.pagination.to = data.to;
            self.pagination.links = data.links;
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card-title">Daftar Banding</div>
                            </div>
                            <div class="col-md-6">
                                <div class="text-end">
                                    &nbsp;
                                    <button class="btn btn-sm btn-success m-1" @click="eksporExcel"><i class="fas fa-file-excel"></i> Cetak
                                        Excel</button>
                                    <button class="btn btn-sm btn-danger m-1" @click="eksporPdf"><i class="fas fa-file-pdf"></i> Cetak
                                        PDF</button>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <hr />
                                <div class="row">
                                    <div class="form-group col-md-2">
                                        <label for="statusTable">Tanggal Dari</label>
                                        <input type="date" v-model="tanggal_dari" id="" class="form-control">
                                    </div>
                                    <div class="form-group col-md-2">
                                        <label for="statusTable">Tanggal Sampai</label>
                                        <input type="date" v-model="tanggal_sampai" id="" class="form-control">
                                    </div>
                                    <div class="form-group col-md-1 mt-4">
                                        <button class="btn btn-info btn-sm" style="margin-top:10px !important" v-on:click="filterDatatable"><i
                                                class="fas fa-filter"></i> Filter</button>
                                    </div>

                                    <div class="form-group col-md-7 mt-4 text-end" v-if="sessionRoleId == '99c668b7-015c-4b68-9761-d2393129d070' || sessionRoleId == '99d70245-6f47-43b6-98d3-b61579bedce1'">
                                        <router-link :to="{ name: 'add-surat-tugas-banding-admin' }" class="btn btn-sm btn-primary m-1"
                                            style="margin-top:10px !important"><i class="fa fa-plus"></i> Tambah Jadwal Rapat Banding</router-link>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div class="col-md-12 mb-2">
                                <div class="row">
                                    <div class="col-md-4">
                                        <table>
                                            <tr>
                                                <td>Show</td>
                                                <td>
                                                    <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%" v-model="tableData.length" @change="getDataTable()">
                                                        <option value="10" selected>10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="200">200</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </td>
                                                <td>Entries</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-md-5">&nbsp;</div>
                                    <div class="col-md-3">
                                        <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..." v-model="tableData.search" @input="getDataTable()" />
                                    </div>
                                </div>
                                <hr />
                                <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                                    <tbody>
                                        <tr v-if="loadingTable">
                                            <td colspan="8" class="text-center"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                        </tr>
                                        <tr v-else-if="table_data.length == 0">
                                            <td class="text-center" colspan="8">Data Tidak Tersedia</td>
                                        </tr>
                                        <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                                            <td class="text-center">
                                                {{ ((parseInt(tableData.length) * parseInt(currentTablePage)) - parseInt(tableData.length)) + key_data + 1}}.
                                            </td>
                                            <td>{{ row_data.rapat_banding_nomor }}</td>
                                            <td class="text-center">{{ row_data.rapat_banding_tanggal ? fullDateTimeFormat(row_data.rapat_banding_tanggal) : 'Data Belum Tersedia' }}</td>
                                            <td class="text-start">
                                                <ul v-if ="row_data.rapat_banding_asesor.length > 0">
                                                    <li v-for="(asesor, key_asesor) in row_data.rapat_banding_asesor" :key="key_asesor">
                                                        {{ asesor.asesor_npk }} - {{ asesor.asesor_nama }}
                                                    </li>
                                                </ul>
                                                <div v-else>
                                                    <p class="text-center">Data Belum Tersedia</p>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <span class="badge bg-warning" v-if="row_data.status_id == '2da54588-68ac-11ee-b509-0242ac11000a'"> {{ row_data.status_nama }}  </span>
                                                <span class="badge bg-info" v-else> {{ row_data.status_nama }}  </span>
                                            </td>
                                            <td class="text-center">
                                                <div class="d-grid gap-1">
                                                    <router-link v-if="row_data.status_id == '91eee742-68ac-11ee-b509-0242ac11000a'"
                                                     :to="{ name: 'add-asesor-banding', params: { id: row_data.id } }" class="btn btn-sm btn-primary bg-primary">
                                                        <i class="fas fa-search"></i> Detail
                                                    </router-link>
                                                    <router-link  v-else :to="{ name: 'detail-banding-admin', params: { id: row_data.id } }" class="btn btn-sm btn-primary bg-primary">
                                                        <i class="fas fa-search"></i> Detail
                                                    </router-link>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </datatable>
                            </div>
                        </div>
                        <pagination :pagination="pagination" @to-page="toPage"></pagination>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
